import type { EmailResponseDto } from 'pages/api/email-service/emails';
import useSWR from 'swr';
import type { PaginatedData, SortModel } from 'types/paginatedData';
import { fetcher } from 'utils/client/swr/jsonFetcher';

type UseEmailsProps = {
  query?: string;
  page?: number;
  pageSize?: number;
  sortModel: SortModel;
};

export default function useEmails({
  query = '',
  page = 0,
  pageSize = 20,
  sortModel: { sort, sortDirection },
}: UseEmailsProps) {
  const params = new URLSearchParams();

  params.set('query', query);
  params.set('page', page.toString());
  params.set('pageSize', pageSize.toString());
  params.set('sort', sort);
  params.set('sortDirection', sortDirection);

  const {
    data,
    isValidating: isLoading,
    error,
    mutate: refresh,
  } = useSWR<PaginatedData<EmailResponseDto>, Error>(
    `email-service/emails?${params.toString()}`,
    fetcher,
  );

  const { data: emails = [], meta } =
    (data as PaginatedData<EmailResponseDto>) || {};

  return { emails, meta, isLoading, error, refresh };
}
